import './App.css';
import HubspotForm from "react-hubspot-form";
import {Spinner} from "./Spinner";

const Loading = () => <div className="SpinnerContainer"><Spinner /></div>

const AppPage = () => {
    return <div className="App">
        <HubspotForm
            portalId='25998083'
            formId='38bb1179-f24a-4010-ab79-89a90c91a1b9'
            loading={<Loading />}
        />
    </div>
}

export default AppPage;